<template>
  <CChartBarStatistic
    :labels="items.labels"
    title="Статистика поверок"
    :datasets="items.datasets"
  />
</template>

<script>
import CChartBarStatistic from '../charts/CChartBarStatistic.vue';
import { getStyle, hexToRgba } from '@coreui/utils/src'

const axios = require("axios");


function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default {
  name: 'MainChart',
  components: {
    CChartBarStatistic
  },
  props: {
    type: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      items: [],
    }
  },
  computed: {
    defaultDatasets () {
      const brandSuccess = getStyle('success2') || '#4dbd74'
      const brandInfo = getStyle('info') || '#20a8d8'
      const brandDanger = getStyle('danger') || '#f86c6b'

      let elements = 27
      const data1 = []
      const data2 = []
      const data3 = []

      for (let i = 0; i <= elements; i++) {
        data1.push(random(50, 200))
        data2.push(random(80, 100))
        data3.push(65)
      }
      return [
        {
          label: 'Акты',
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: data1
        },
        {
          label: 'Счетчики',
          backgroundColor: 'transparent',
          borderColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          borderWidth: 2,
          data: data2
        },
        {
          label: 'С чеками',
          backgroundColor: 'transparent',
          borderColor: brandDanger,
          pointHoverBackgroundColor: brandDanger,
          borderWidth: 1,
          borderDash: [8, 5],
          data: data3
        }
      ]
    },
    defaultOptions () {
      return {

        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              stepSize: Math.ceil(250 / 5),
              max: 250
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  },
  created() {
    this.getData();

  },
  watch: {
    type: function () {
      this.getData();
    }
  },
  methods: {
    getData() {
      axios.post(`/api/get-statistic-total`, {type: this.type})
        .then((resp) => {
          this.items = resp.data.data;
        });
    },
  }
}
</script>
