<template>
  <CChartBar
    :datasets="datasets"
    :labels="labels"
  />
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'


export default {
  name: 'CChartBarStatistic',
  components: { CChartBar },
  props: {
    title: {
      type: String,
      default: ''
    },
    labels: {
      type: Array
    },
    datasets: {
      type: Array
    }
  },
  computed: {
    defaultDatasets () {
      return [
        {
          label: this.title,
          backgroundColor: '#f87979',
          data: []
        }
      ]
    }
  }
}
</script>
